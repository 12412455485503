@font-face {
    font-family: 'FuturaPTBold';
    src: local('FuturaPTBold'),
         url('./Fonts/futura-pt-bold.ttf');
  }
  
  @font-face {
    font-family: 'FuturaPTLight';
    src: local('FuturaPTLight'),
         url('./Fonts/futura-pt-light.ttf');
  }