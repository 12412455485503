@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.cdnfonts.com/css/futura-pt');
@import url("./font.css");
:root {
  --font-family: 'Futura PT', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}

body {
  background-color: #040C18;
}

@media (max-width: 480px){
  .feed-navbar button h2{
    font-size: 18px !important;
  }
}